<template>
    <b-card-actions action-collapse title="Service Form">
        <validation-observer ref="serviceRules">
            <b-form>
                <b-form-group>
                    <label>ประเภทบริการ: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="service_name"
                        >
                            <b-form-input
                                v-model="Data.name"
                                placeholder="ประเภทบริการ"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                </b-form-group>
                <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                >
                    Submit
                </b-button>
            </b-form>
        </validation-observer>
    </b-card-actions>
</template>

<script>
    import {
        BFormInput, BFormGroup, BForm, BButton
    } from 'bootstrap-vue'
    import { VueAutosuggest } from 'vue-autosuggest'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
    } from '@validations'
    import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
    import axios from '@axios'
    import Ripple from 'vue-ripple-directive'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import router from '@/router'
    import { ref, watch, onMounted } from "@vue/composition-api";
    import store from '@/store'
    import phonebookStoreModule from '../phonebookStoreModule'

    export default {
        components: {
            BCardActions,
            VueAutosuggest,
            ValidationProvider,
            ValidationObserver,
            BFormInput,
            BFormGroup,
            BForm,
            BButton,
        },

        setup() {
            const GROUP_PHONEBOOK_APP_STORE_MODULE_NAME = 'app-phone-book'
            if (!store.hasModule(GROUP_PHONEBOOK_APP_STORE_MODULE_NAME)) store.registerModule(GROUP_PHONEBOOK_APP_STORE_MODULE_NAME, phonebookStoreModule)
        
            const userStorage = JSON.parse(localStorage.getItem('userData'))

            const blankData = ref({
                name: '',
                created_by: userStorage.id
            })

            const Data = ref(blankData)

            const serviceId = ref(router.currentRoute.params.id)

            watch(() => router.currentRoute.value?.params?.id, (newId) => {
                    serviceId.value = newId;
                    getData();
                }
            );

            const getData = async function () {
                if(serviceId.value){
                    store
                        .dispatch("app-phone-book/fetchPhonebookServices", {
                            id: serviceId.value
                        })
                        .then((response) => {
                            Data.value = response.data.data[0]
                        }).catch((error) => {
                            this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                        })
                }
            }

            onMounted(() => {
                getData();
            });

            const onSubmit = async function () {
                if(!serviceId.value){
                    store
                        .dispatch("app-phone-book/addPhoneBookService", Data.value)
                        .then((res) => {
                                this.$router.push('/phonebook/service/lists', () => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Done`,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                            text: `You have successfully created.`,
                                        },
                                    })
                                })
                            })
                        .catch((error) => {
                            this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                        })
                } else {
                    Data.value.updated_by = userStorage.id

                    store
                        .dispatch("app-phone-book/updatePhoneBookService", {
                            id: serviceId.value,
                            data: Data.value
                        })
                        .then((res) => {
                                this.$router.push('/phonebook/service/lists', () => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Done`,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                            text: `You have successfully updated.`,
                                        },
                                    })
                                })
                            })
                        .catch((error) => {
                            this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                        })
                }
            }

            return {
                Data,
                onSubmit,
                getData,
                serviceId,
            }
        },

        methods: {
            async validationForm() {
                this.$refs.serviceRules.validate().then(success => {
                    if(success){
                        this.onSubmit()
                    }
                });
            },

            makeToast(variant = null, title, message) {
                this.$bvToast.toast(message, {
                    title: title,
                    variant,
                    solid: true,
                })
            },
        }
    }
</script>